import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import { LgHeadline, MdHeadline } from '../styles/SharedStyles'
import SectionNav from './SectionNav'
import LangSelector from './LangSelector'
import { MediaQueries as Mq } from '../styles/MediaQueries'
import ShareButton from '../components/ShareButton'
import {
  WHO_LOCATION_OPTIONS,
  INC_LOCATION_OPTIONS,
  UNI_LOCATION_OPTIONS
} from '../utils/const'

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 22.25rem;
  color: var(--white);
  background-image: url('/svgs/background_element.svg');
  background-size: cover;
  background-color: var(--baseBlue);
  ${Mq.sm} {
    height: 15.25rem;
  }
`
const TitleContainer = styled.div`
  max-width: var(--contentWidth);
  //margin: 0 auto;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  //height: 100%;
  padding: 0 4rem;
  h1 {
    margin: -1rem 0;
  }
  h2 {
    margin: -0.5rem 0;
    line-height: 1;
  }
  ${Mq.sm} {
    padding: 0 1rem;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`
const Section = styled.div`
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--paleBlue);
  color: var(--baseBlue);
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 50%;
  margin: 0 0.75rem 0.5rem 0;
`
const EyebrowText = styled.div`
  font-size: 1.75rem;
  ${Mq.sm} {
    font-size: 1.5rem;
  }
  ${Mq.xs} {
    font-size: 1.2rem;
  }
`
const LangBox = styled.div`
  left: 4.2rem;
  top: 130px;
  ${Mq.md} {
    top: 125px;
  }
  ${Mq.sm} {
    display: none;
  }
`

const RegionList = styled.ul`
  display: flex;
  flex-direction: row;
  color: white;
  font-size: 20px;
  ${Mq.md} {
    font-size: 17px;
  }
  ${Mq.sm} {
    font-size: 14px;
  }
  ${Mq.xs} {
    flex-direction: column;
    margin-top: -0.5rem;
  }
`
const RegionSection = styled.li`
  padding: 0.5rem 2.5rem;
  :first-child {
    padding: 0.5rem 2.5rem 0 0;
  }
  border-right: 1px solid white;
  :last-child {
    border-right: none;
  }
  ${Mq.xs} {
    border-right: none;
    padding: 0.25rem 0;
  }
`

const TitleSection = ({
  sectionId,
  title,
  eyebrowText,
  region,
  incomeGroup,
  unicefRegion
}) => {
  const { t } = useTranslation()
  const regionList = [
    {
      group: 'WHO Region',
      name:
        sectionId === 'CTY'
          ? WHO_LOCATION_OPTIONS.find((item) => item.id === region).label
          : null
    },
    {
      group: 'UNICEF Programme Region',
      name:
        sectionId === 'CTY'
          ? unicefRegion === 'Non-Programme'
            ? 'Non-Programme'
            : UNI_LOCATION_OPTIONS.find((item) => item.id === unicefRegion)
                .label
          : null
    },
    {
      group: 'World Bank Income Group',
      name:
        sectionId === 'CTY'
          ? incomeGroup === 'Uncategorized'
            ? 'Uncategorized'
            : INC_LOCATION_OPTIONS.find((item) => item.id === incomeGroup).label
          : null
    }
  ]
  return (
    <HeroContainer>
      <SectionNav />
      <TitleContainer>
        <Title>
          <LangBox>
            <LangSelector />
          </LangBox>
          {sectionId === 'Overview' ? (
            <>
              <EyebrowText>
                {t('Immunization Agenda 2030 Scorecard')}
              </EyebrowText>
              <LgHeadline>{t(title)}</LgHeadline>
            </>
          ) : (
            <>
              <EyebrowText>
                {sectionId !== 'CTY' && `${eyebrowText}`}
              </EyebrowText>
              <Flex>
                {sectionId !== 'CTY' && <Section>{sectionId}</Section>}
                <MdHeadline size={sectionId === 'CTY' ? '4.375rem' : null}>
                  {t(title)}
                </MdHeadline>
              </Flex>
              {sectionId === 'CTY' && (
                <RegionList>
                  {regionList.map((item) => (
                    <React.Fragment key={item.name}>
                      <RegionSection>
                        <div>{t(item.group)}</div>
                        <div>
                          <strong>{t(item.name)}</strong>
                        </div>
                      </RegionSection>
                    </React.Fragment>
                  ))}
                </RegionList>
              )}
            </>
          )}
        </Title>
        <ShareButton />
      </TitleContainer>
    </HeroContainer>
  )
}
export default TitleSection
