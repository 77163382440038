import React, { useState, useEffect } from 'react'
import { RiShareCircleLine } from 'react-icons/ri'
import { CopyToClipboard } from 'react-copy-to-clipboard'
//import { useI18next } from 'gatsby-plugin-react-i18next'
import { StyledButton } from '../styles/SharedStyles'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ShareButton = () => {
  const [location, setLocation] = useState()
  const { t } = useTranslation()
  //can't add dependency array because doesn't get query param if you do
  // eslint-disable-next-line
  useEffect(() => {
    setLocation(window.location.href)
  })

  return (
    <>
      <CopyToClipboard text={location}>
        <StyledButton ml={'4rem'}>
          <div className="space">{t('share this page')}</div>

          <RiShareCircleLine />
        </StyledButton>
      </CopyToClipboard>
    </>
  )
}

export default ShareButton
