import styled from 'styled-components'
import { MediaQueries as Mq } from '../styles/MediaQueries'

export const PageContent = styled.div`
  max-width: var(--contentWidth);
  margin: 0 auto;
  padding: 4rem 4rem;
  ${Mq.xs} {
    padding: 2rem 1rem;
  }
`
export const ChartTitle = styled.span`
  font-weight: bold;
  font-size: 1.7rem;
  color: var(--baseBlue);
  ${Mq.xs} {
    font-size: 1.4rem;
  }
`
//headline font now set in typography.js
export const LgHeadline = styled.h1`
  // font-family: BebasNeue;
  font-size: 6rem;
  line-height: 1;
  padding: 1rem 0;

  ${Mq.md} {
    font-size: 4.5rem;
    line-height: 1;
  }
  ${Mq.xs} {
    font-size: 4rem;
  }
`

export const MdHeadline = styled.h2`
  //font-family: BebasNeue;
  font-size: ${({ size }) => (size ? `${size}` : '3.75rem')};
  padding: 1rem 0;
  color: ${({ color }) => `var(--${color})`};
  ${Mq.sm} {
    font-size: 2.8rem;
  }
  ${Mq.xs} {
    font-size: 2rem;
  }
`

export const SmHeadline = styled.h3`
  // font-family: BebasNeue;
  font-size: 2.5rem;
  line-height: 1.16;
  color: ${({ color }) => `var(--${color})`};
`

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  //width: 11rem;
  //min-width: 13rem;
  background-color: var(--darkBlue);
  opacity: 0.8;
  border: none;
  border-radius: 50px;
  font-size: 1.05rem;
  color: white;
  padding: 0 0.8rem;
  margin-left: ${({ ml }) => ml};
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
  cursor: pointer;
  transition: all 100ms ease;
  .space {
    margin-right: 1rem;
  }
  &:hover {
    background-color: var(--brightBlue);
  }
  // &:focus {
  //   outline: 2px solid red;
  // }
  &:active {
    background-color: var(--baseBlue);
  }
  ${Mq.sm} {
    margin: 1rem 0 1rem 1rem;
    &.download {
      margin-left: 0;
    }
  }
`
