import React, { useContext } from 'react'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
//import { graphql, useStaticQuery } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import { LOCATION_COLORS, LOCATION_LABELS } from '../utils/const'
//import { navigate } from 'gatsby-link'
import NavContext from './NavContext'
import { MediaQueries as Mq } from '../styles/MediaQueries'
import { GiHamburgerMenu } from 'react-icons/gi'
//import { BsArrowLeft } from 'react-icons/bs'
//import { TiHome } from 'react-icons/ti'
import LangSelector from './LangSelector'
import { getQueryParams } from 'react-use-query-param-string'

const LocationBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  //height: 3rem;
  position: sticky;
  top: 0;
  z-index: 10;
  ${Mq.sm} {
    flex-direction: column;
  }
`

const LocationIndicator = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  //height: 100%;
  background-color: ${(props) => `var(--${props.color})`};
  color: var(--white);
  font-size: 1.375rem;

  ${Mq.sm} {
    width: 100%;
    padding: 0.5rem 1rem;
    justify-content: space-between;
  }
`
const MobileHamburger = styled.button`
  display: none;
  ${Mq.sm} {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: block;
  }
`
const LocationHome = styled.div`
  display: flex;
  align-items: center;
`
// const HomeLink = styled.a`
//   display: flex;
//   align-items: center;
//   text-transform: uppercase;
//   letter-spacing: ${(props) => (props.lang === 'fr' ? 0 : '0.2rem')};
//   padding: 0.2rem 1.2rem;
//   border: 1px solid var(--white);
//   border-radius: 5px;
//   font-size: ${(props) => (props.lang === 'fr' ? '.75rem' : '1.05rem')};
//   margin: 0 1.2rem;
//   text-decoration: none;
//   color: var(--white);
//   &:visited {
//     color: var(--white);
//   }
//   p {
//     margin-left: 0.5rem;
//   }
//   ${Mq.sm} {
//     display: none;
//   }
// `

// const MobileHomeLink = styled.a`
//   display: none;
//   ${Mq.sm} {
//     display: block;
//     margin-right: 0.75rem;
//     padding-top: 0.05rem;
//   }
// `
const LocationLabel = styled.p`
  font-weight: bold;
`

// const SelectorButton = styled.button`
//   background-color: var(--brightBlue);
//   border: none;
//   color: white;
//   padding: 0 2rem;
//   //padding: 0.3rem 3.5rem 0.3rem 2rem;
//   text-align: left;
//   text-decoration: none;
//   font-size: 1.25rem;
//   width: 30%;
//   // line-height: 1.25;
//   // ${Mq.md} {
//   //   font-size: 1.15rem;
//   // }
//   // ${Mq.sm} {
//   //   font-size: 1.2rem;
//   // }
//   .space {
//     margin-right: 1rem;
//   }
//   &:hover {
//     background-color: var(--baseBlue);
//     ${Mq.sm} {
//       background-color: var(--brightBlue);
//       opacity: 0.9;
//     }
//   }
//   &:focus {
//     outline-offset: -2px;
//   }
//   &:after {
//     content: '';
//     position: absolute;
//     top: 20px;
//     right: 15px;
//     width: 0;
//     height: 0;
//     border-left: 13px solid transparent;
//     border-right: 13px solid transparent;
//     border-top: 11px solid var(--white);
//     cursor: pointer;
//     ${Mq.sm} {
//       top: 4rem;
//     }
//   }
//   ${Mq.sm} {
//     width: 100%;
//     padding: 0.5rem 1rem;
//   }
// `
// const DropDownContainer = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   width: 100%;
//   position: sticky;
//   top: 3rem;
//   z-index: 10;
//   ${Mq.sm} {
//     top: 5.5rem;
//   }
// `
// const DropDown = styled.div`
//   position: absolute;
//   right: 0;
//   width: 30%;
//   background-color: var(--brightBlue);
//   display: ${(props) => (props.open ? 'block' : 'none')};
//   padding-bottom: 1rem;
//   ${Mq.sm} {
//     width: 100%;
//   }
// `
// const OptionButton = styled.button`
//   background-color: var(--brightBlue);
//   border: none;
//   color: white;
//   padding: 0.5rem 2rem;
//   text-align: left;
//   text-decoration: none;
//   font-size: 1.25rem;
//   font-weight: bold;
//   width: 100%;
//   &:hover {
//     background-color: var(--baseBlue);
//   }
//   &:focus {
//     outline-offset: -2px;
//   }
// `
const MobileLangBox = styled.div`
  display: none;
  ${Mq.sm} {
    display: block;
    position: absolute;
    top: 0;
    right: 3.5rem;
  }
`
const LocationNav = () => {
  //const [isOpen, setOpen] = useState(false)
  const { isMobileOpen, setMobileOpen } = useContext(NavContext)
  const { originalPath } = useI18next()
  const { t } = useTranslation()

  //doesn't work to get this from hook -- maybe b/c Layout component is outside of page
  //const language = context.language
  //const { originalPath } = context.i18n

  //color and label always come from the path, because context gets reset on page reload
  // const getRegionFromPath = () => {
  //   const params = originalPath.split('/')

  //   if (
  //     originalPath === '/' ||
  //     params[1].startsWith('ig') ||
  //     params[1].startsWith('sp')
  //   )
  //     //then there is no region so
  //     return 'GBL'
  //   //else return region param
  //   else return params[1].toUpperCase()
  // }
  const isRegionOrCountry = () => {
    const qparams = getQueryParams()
    const params = originalPath.split('/')
    const param = params[1]
    const region = qparams.reg ?? 'GBL'
    //if (region !== 'GBL' && !REGIONS_LIST.includes(region.toLowerCase()))
    if (param === 'country') return 'CTY'
    else return region
  }
  //this is only for styling purposes
  const regionISO = isRegionOrCountry()

  //if want/need to pull region labels from sheet:
  // const { regions } = useStaticQuery(graphql`
  //   query {
  //     regions: allGoogleIg11Sheet {
  //       nodes {
  //         id
  //         memberState
  //         iSO3
  //       }
  //     }
  //   }
  // `)

  // const { memberState: regionName } = regions.nodes.find(
  //   (node) => node.iSO3 === regionISO
  // )

  //this handles when load site at url that includes section, i.e. section needs to come from path
  // const getSectionFromPath = () => {
  //   const params = originalPath.split('/')
  //   if (params[1]?.startsWith('ig')) setSection(params[1])
  //   if (params[2]?.startsWith('ig')) setSection(params[2])
  //   if (params[1]?.startsWith('sp')) setSection(params[1])
  //   if (params[2]?.startsWith('sp')) setSection(params[2])
  // }

  // const handleMenu = () => {
  //   setOpen(!isOpen)
  //   getSectionFromPath()
  // }

  // const handleNavigation = (key) => {
  //   const routeKey = key.toLowerCase()
  //   setOpen(!isOpen)
  //   setRegion(key)
  //   if (key === 'GBL') {
  //     if (!section) {
  //       //this is the 'navigate' function from reacti18next, a wrapper for the gatsby one.
  //       //it preserves the lang in the route.
  //       navigate('/')
  //     } else navigate(`/${section}`)
  //   } else {
  //     navigate(`/${routeKey}/${section}`)
  //   }
  // }
  const isMedium = useMediaQuery({ query: '(max-width: 900px)' })
  const isXlg = useMediaQuery({ query: '(max-width: 1235px)' })

  return (
    <>
      <LocationBar>
        {/* removing nav bar color -- just blue now */}
        <LocationIndicator color={LOCATION_COLORS['GBL']}>
          <LocationHome>
            {/* <HomeLink
              href={'https://immunizationagenda2030.org'}
              lang={language}
            >
              <BsArrowLeft color={'white'} size={26} />
              <p>{t('return home')}</p>
            </HomeLink> */}
            {/* <MobileHomeLink href={'https://immunizationagenda2030.org'}>
              <TiHome color={'white'} size={26} />
            </MobileHomeLink> */}
            {isMedium ? (
              <>
                <p>IA2030</p>
                {regionISO === 'CTY' ? null : <span>&nbsp;—&nbsp;</span>}
              </>
            ) : (
              <>
                <p style={{ whiteSpace: 'nowrap' }}>{t('ia2030 scorecard')}</p>
                {regionISO === 'CTY' ? null : <span>&nbsp;—&nbsp;</span>}
              </>
            )}
            <LocationLabel>
              {isXlg
                ? regionISO === 'GBL'
                  ? t('global')
                  : regionISO === 'CTY'
                  ? ''
                  : `${regionISO}`
                : regionISO === 'CTY'
                ? ''
                : t(LOCATION_LABELS[regionISO])}
            </LocationLabel>
          </LocationHome>
          <MobileLangBox>
            <LangSelector />
          </MobileLangBox>
          <MobileHamburger
            title="open mobile menu"
            onClick={() => setMobileOpen(!isMobileOpen)}
          >
            <GiHamburgerMenu color={'white'} size={26} />
          </MobileHamburger>
        </LocationIndicator>
        {/* <SelectorButton onClick={handleMenu} aria-haspopup="menu">
         // <Trans>Select a Region or Income Group</Trans> 
          <div className="space">{t('select a region')}</div>
        </SelectorButton> */}
      </LocationBar>
      {/* <DropDownContainer>
        <DropDown open={isOpen} title="menu to select a region" role="menu">
          {Object.entries(LOCATION_LABELS).map(([key, value]) => (
            <OptionButton key={key} onClick={() => handleNavigation(key)}>
              {value === 'Global' ? t(`${value} — All Regions`) : t(value)}
            </OptionButton>
          ))}
        </DropDown>
      </DropDownContainer> */}
    </>
  )
}

export default LocationNav
