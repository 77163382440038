import React from 'react'
//import { graphql } from 'gatsby'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import { MediaQueries as Mq } from '../styles/MediaQueries'
import { useMediaQuery } from 'react-responsive'

const StyledUl = styled.ul`
  display: flex;
  // //position: absolute;
  // left: 4.2rem;
  // top: 130px;
  // ${Mq.md} {
  //   top: 125px;
  // }
  // ${Mq.sm} {
  //   position: absolute;
  //   top: 0;
  //   left: unset;
  //   right: 3.5rem;
  //   z-index: 20;
  // }
`

const StyledButton = styled.button`
  border-radius: 0px;
  border: none;
  margin-right: 1px;
  background-color: var(--brightBlue);
  font-size: 1rem;
  padding: 0.3rem 0.6rem;
  &:hover {
    background-color: var(--darkBlue);
  }

  ${Mq.sm} {
    height: 51px;
    padding: 0 0.5rem;
    background-color: var(--baseBlue);
    &:hover {
      background-color: var(--brightBlue);
    }
  }
`
const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:visted {
    color: white;
    text-decoration: none;
  }
`
const LangSelector = () => {
  const { languages, originalPath } = useI18next()

  const langName = { en: 'English', fr: 'Français' }
  const matches = useMediaQuery({ query: '(max-width: 500px)' })
  return (
    <header>
      <StyledUl>
        {languages.map((lng) => (
          <li key={lng}>
            <StyledButton>
              <StyledLink to={originalPath} language={lng}>
                {matches ? lng.toUpperCase() : langName[lng]}
              </StyledLink>
            </StyledButton>
          </li>
        ))}
      </StyledUl>
    </header>
  )
}

export default LangSelector
