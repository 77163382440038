import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MediaQueries as Mq } from '../styles/MediaQueries'
import logos from '../images/logos.png'
import { useTranslation } from 'react-i18next'

const StyledBar = styled.div`
  width: 100%;
  background-color: var(--baseBlue);
`

const Container = styled.div`
  max-width: var(--contentWidth);
  margin: 0 auto;
  padding: 3rem 4rem 4rem;
  ${Mq.sm} {
    padding: 2rem;
  }
  span {
    font-weight: bold;
    color: var(--white);
  }
`
const Note = styled.span`
  margin-bottom: ${({ citation }) => (citation ? 0 : '1rem')};
  color: var(--white);
  display: inline;

  a {
    color: var(--white);
    :visited {
      color: var(--white);
    }
  }
`
const LogoBox = styled.div`
  margin: 2rem 0 0 0;
  img {
    width: 20%;
    margin: 0;
  }
`
// const SmNote = styled.div`
//   font-size: 1rem;
//   color: var(--mediumBlue);
// `

const StyledHr = styled.hr`
  border-top: 1px solid var(--paleBlue);
  background: none;
  margin: 1rem 0;
`
const Footer = ({ content }) => {
  //const citationRef = createRef()
  const { t } = useTranslation()
  const [date, setDate] = useState()
  //this puts "Accessed: date" inside the Note div and on same line as rest of citation.
  useEffect(() => {
    // function insertAfter(newNode, existingNode) {
    //   existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling)
    // }
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
    const date = new Date().toLocaleDateString(undefined, options)
    setDate(date)
    // const span = document.createElement('span')
    // span.textContent = ` ${t('accessed')}: ${date}`
    //insertAfter(span, citationRef.current.lastElementChild)
  }, [])

  return (
    <StyledBar>
      <Container>
        <Note dangerouslySetInnerHTML={{ __html: content.descriptionRt }} />

        <StyledHr />
        {/* <SmNote dangerouslySetInnerHTML={{ __html: content.disclaimerRt }} /> */}

        <Note
          dangerouslySetInnerHTML={{ __html: content.citationRt }}
          citation={true}
          //ref={citationRef}
        />
        <span>{`${t('accessed')}: ${date}`}</span>
        <LogoBox>
          <img src={logos} alt={'US AID and Momemtum logos'} />
        </LogoBox>
      </Container>
    </StyledBar>
  )
}

export default Footer
