import React from 'react'

// import GlobalStyles from '../styles/GlobalStyles'
// import Typography from '../styles/Typography'

import LocationNav from './LocationNav'
import TitleSection from './TitleSection'

const PageHeader = ({
  sectionId,
  title,
  eyebrowText,
  region,
  incomeGroup,
  unicefRegion
}) => {
  return (
    <>
      {/* <GlobalStyles />
      <Typography /> */}
      <LocationNav />
      <TitleSection
        title={title}
        sectionId={sectionId}
        eyebrowText={eyebrowText}
        region={region}
        incomeGroup={incomeGroup}
        unicefRegion={unicefRegion}
      />
    </>
  )
}
export default PageHeader
