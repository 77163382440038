export const COLORS = {
  darkBlue: '#254272',
  baseBlue: '#386099',
  brightBlue: '#4681de'
}

export const LOCATION_COLORS = {
  AFR: 'aqua',
  AMR: 'purple',
  EMR: 'slate',
  EUR: 'lime',
  WPR: 'orange',
  SEAR: 'yellow',
  GBL: 'darkBlue',
  CTY: 'darkBlue',
  WBLIC: 'dirtyOlive',
  WBLMIC: 'dirtyOlive',
  WBUMIC: 'dirtyOlive',
  WBHIC: 'dirtyOlive'
}
export const WHO_LOCATION_OPTIONS = [
  //{ id: 'GBL', label: 'Global — All Regions' },

  { id: 'AFR', label: 'African Region' },
  { id: 'EMR', label: 'Eastern Mediterranean Region' },
  { id: 'EUR', label: 'European Region' },
  { id: 'AMR', label: 'Region of the Americas' },
  { id: 'SEAR', label: 'South-East Asian Region' },
  { id: 'WPR', label: 'Western Pacific Region' }
]
export const INC_LOCATION_OPTIONS = [
  { id: 'WBLIC', label: 'Low Income' },
  { id: 'WBLMIC', label: 'Low-Middle Income' },
  { id: 'WBUMIC', label: 'Upper-Middle Income' },
  { id: 'WBHIC', label: 'High Income' }
]
export const UNI_LOCATION_OPTIONS = [
  { id: 'EAPR', label: 'East Asia and Pacific Region' },
  { id: 'ESAR', label: 'Eastern and Southern Africa Region' },
  { id: 'ECAR', label: 'Europe and Central Asia Region' },
  { id: 'LACR', label: 'Latin America and the Caribbean Region' },
  { id: 'MENA', label: 'Middle East and North Africa Region' },
  { id: 'ROSA', label: 'South Asia Region' },
  { id: 'WCAR', label: 'West and Central Africa Region' }
]

export const WHO_LOCATION_OPTIONS_ALT = [
  { id: 'AFR', label: 'African Region', disabled: true },
  { id: 'EMR', label: 'Eastern Mediterranean Region', disabled: true },
  { id: 'EUR', label: 'European Region', disabled: true },
  { id: 'AMR', label: 'Region of the Americas', disabled: true },
  { id: 'SEAR', label: 'South-East Asian Region', disabled: true },
  { id: 'WPR', label: 'Western Pacific Region', disabled: true }
]
export const INC_LOCATION_OPTIONS_ALT = [
  { id: 'WBLIC', label: 'Low Income', disabled: true },
  { id: 'WBLMIC', label: 'Low-Middle Income', disabled: true },
  { id: 'WBUMIC', label: 'Upper-Middle Income', disabled: true },
  { id: 'WBHIC', label: 'High Income Countries', disabled: true }
]
export const UNI_LOCATION_OPTIONS_ALT = [
  { id: 'EAPR', label: 'East Asia and Pacific Region', disabled: true },
  { id: 'ESAR', label: 'Eastern and Southern Africa Region', disabled: true },
  { id: 'ECAR', label: 'Europe and Central Asia Region', disabled: true },
  {
    id: 'LACR',
    label: 'Latin America and the Caribbean Region',
    disabled: true
  },
  { id: 'MENA', label: 'Middle East and North Africa Region', disabled: true },
  { id: 'ROSA', label: 'South Asia Region', disabled: true },
  { id: 'WCAR', label: 'West and Central Africa Region', disabled: true }
]
export const LOCATION_LABELS = {
  GBL: 'Global',
  AFR: 'WHO African Region',
  AMR: 'WHO Region of the Americas',
  EMR: 'WHO Eastern Mediterranean Region',
  EUR: 'WHO European Region',
  SEAR: 'WHO South-East Asian Region',
  WPR: 'WHO Western Pacific Region',
  WBLIC: 'WB Low Income Countries',
  WBLMIC: 'WB Lower-Middle Income Countries',
  WBUMIC: 'WB Upper-Middle Income Countries',
  WBHIC: 'WB High Income Countries',
  EAPR: 'UNICEF East Asia and Pacific Region',
  ECAR: 'UNICEF Europe and Central Asia Region',
  ESAR: 'UNICEF Eastern and Southern Africa Region',
  LACR: 'UNICEF Latin America and the Caribbean Region',
  MENA: 'UNICEF Middle East and North Africa Region',
  ROSA: 'UNICEF South Asia Region',
  WCAR: 'UNICEF West and Central Africa Region'
}

export const REGIONS_LIST = [
  'amr',
  'afr',
  'emr',
  'eur',
  'sear',
  'wpr',
  'wblic',
  'wblmic',
  'wbumic',
  'wbhic'
]

export const WHO_REGIONS = ['AFR', 'AMR', 'EMR', 'EUR', 'SEAR', 'WPR']

export const INCOME_GROUPS = ['WBLIC', 'WBLMIC', 'WBUMIC', 'WBHIC']

export const UNI_REGIONS = [
  'EAPR',
  'ECAR',
  'ESAR',
  'LACR',
  'MENA',
  'ROSA',
  'WCAR'
]

export const IG_LABELS = {
  1.1: 'Save Lives',
  1.2: 'Control, eliminate & eradicate VPDs',
  1.3: 'Reduce VPD outbreaks',
  2.1: 'Leave no child behind',
  2.2: 'Provide access to all vaccines',
  3.1: 'Deliver across the life course',
  3.2: 'Strengthen universal health coverage'
}

export const SP_LABELS = {
  1.1: 'Leadership, management, coordination',
  1.2: 'Health workforce',
  1.3: 'Surveillance',
  1.4: 'Supply chain',
  1.6: 'Vaccine safety',
  2.1: 'Political and financial commitment',
  2.2: 'Demand for immunization',
  3.2: 'High & equitable coverage',
  4.1: 'Life-course vaccination',
  5.1: 'Outbreak detection and response',
  6.1: 'Healthy global markets for vaccines',
  6.2: 'Financial resources for imm. programmes',
  6.3: 'Imm. expenditure from domestic resources',
  7.1: 'Capacity for innovation',
  7.2: 'New vaccine development'
}

export const PATHOGEN_LABELS2 = {
  hepB: 'Hepatitis B',
  hib: 'Hib',
  hPV: 'HPV',
  jE: 'JE',
  measles: 'Measles',
  men: 'MenA',
  pcV: 'Streptococcus pneumoniae',
  rota: 'Rotavirus',
  rubella: 'Rubella',
  yF: 'Yellow fever',
  diphtheria: 'Diphtheria',
  tetanus: 'Tetanus',
  pertussis: 'Pertussis',
  tB: 'TB (BCG)',
  totalDeath: 'Total',
  mean: 'Mean',
  dTP3: 'DTP3',
  iPV: 'IPV',
  mCV2: 'MCV2',
  pneumo: 'Pneumonia',
  wPV: 'WPV'
}

export const PATHOGEN_LABELS_API = {
  HEPATITISB: 'Hepatitis B',
  HIB: 'Hib',
  HPV: 'HPV',
  JAPENC: 'JE',
  MEASLES: 'Measles',
  MENA: 'MenA',
  PNEUMOCOCCUS: 'Streptococcus pneumoniae',
  ROTAVIRUS: 'Rotavirus',
  RUBELLA: 'Rubella',
  YFEVER: 'Yellow Fever',
  DIPHTHERIA: 'Diphtheria',
  TETANUS: 'Tetanus',
  NTETANUS: 'Maternal & Neonatal Tetanus',
  PERTUSSIS: 'Pertussis',
  TUBERCULOSIS: 'TB (BCG)',
  POLIO: 'Poliovirus (WPV)', //ig 1.2
  POLIO_WILD: 'Poliovirus (WPV)', //ig1.3
  POLIO_VAC: 'Poliovirus (cVDPV)',
  EBOLA: 'Ebola',
  CHOLERA: 'Cholera',
  MENINGOCOCCUS: 'Meningococcus',
  POLIO_CVDPV: 'Poliovirus (cVDPV)',
  Total: 'Total',
  mean: 'Mean',
  dTP3: 'DTP3',
  iPV: 'IPV',
  mCV2: 'MCV2',
  pneumo: 'Pneumonia',
  wPV: 'WPV'
}
//long version for sp 6.1
export const VACCINE_LABELS_API = {
  BCG: 'Bacille Calmette-Guerin (BCG)',
  DTAPHIBHEPIPV: 'Hexa (acellular pertussis-containing)',
  DTWPHIBHEP: 'Penta (whole-cell pertussis-containing)',
  HPV: 'Human papillomavirus (HPV)',
  IPV: 'Inactivated polio vaccine, stand-alone',
  MEASLES: 'Measles',
  MMR: 'Measles-mumps-rubella (MMR)',
  MR: 'Measles-rubella',
  PNEUMO_CONJ: 'Pneumococcal conjugate vaccine (PCV)',
  PNEUMO_PS: 'Pneumococcal polysaccharide',
  ROTAVIRUS: 'Rotavirus',
  TD: 'Tetanus-diphtheria',
  CHOLERA: 'Cholera',
  YFEVER: 'Yellow fever'
}
export const ABBR_TO_VACCINE = {
  dTPcv3Coverage: 'DTP3',
  hPVc: 'HPVc',
  mCV2: 'MCV2',
  pCV3: 'PCV3'
}
export const ANTIGEN_LOOKUP_API = {
  DTPCV3: 'DTP3',
  PCV3: 'PCV3',
  MCV2: 'MCV2',
  PRHPVC_F: 'HPVc',
  HEPB3: 'HEPB3',
  HIB3: 'HIB3',
  IPV1: 'IPV1',
  MCV1: 'MCV1',
  POL3: 'POL3',
  RCV1: 'RCV1',
  ROTAC: 'ROTAC',
  BOP: 'BOP',
  null: 'BOP' //for SP4.1
}
export const ANTIGEN_COLORS = {
  dTPcv3Coverage: 'brightBlue',
  pCV3: 'purple',
  mCV2: 'rose',
  hPVc: 'aqua'
}
export const CHART_TITLES_IG31_API = {
  DTP3: 'First Year',
  PCV3: 'First Year',
  MCV2: 'Second Year',
  HPVc: 'Adolescents'
}
export const CHART_TITLES_IG31 = {
  dTPcv3Coverage: 'First Year',
  pCV3: 'First Year',
  mCV2: 'Second Year',
  hPVc: 'Adolescents'
}

export const PATHOGEN_LABELS = {
  cholera: 'Cholera',
  ebola: 'Ebola',
  measles: 'Measles',
  mening: 'Meningococcus',
  cVDPV: 'Poliovirus (cVDPV)',
  wPV: 'Poliovirus (WPV)',
  yellowFever: 'Yellow Fever',
  jev: 'Japanese Enc. Virus',
  rubella: 'Rubella',
  hpv: 'Human Papilloma Virus',
  tetanus: 'Tetanus',
  hepb: 'Hepatitis B',
  mNT: 'Maternal & Neonatal Tetanus'
}

export const NUM_YEARS = 10

export const GBL_NUM_COUNTRIES = 194

export const NUM_COUNTRIES_IN_REG = {
  GBL: 194,
  AMR: 35,
  AFR: 47,
  EMR: 21,
  EUR: 53,
  SEAR: 11,
  WPR: 27,
  WBHIC: 59,
  WBLIC: 26,
  WBLMIC: 54,
  WBUMIC: 53,
  EAPR: 29,
  ECAR: 21,
  ESAR: 23,
  LACR: 33,
  MENA: 19,
  ROSA: 8,
  WCAR: 24
}

export const CHART_TITLES_IG12 = {
  jev: 'Japanese Encephalitis Virus — Control Target',
  measles: 'Measles — Elimination of Transmission Target',
  rubella: 'Rubella — Elimination of Transmission Target',
  hpv: 'Hepatitis B Virus — Elimination of Epidemic Target',
  tetanus: 'Tetanus — Elimination of Epidemic Target',
  hepb: 'Hepatitis B Virus — Elimination of Epidemic Target',
  wPV: 'Wildtype Poliovirus — Eradication Target',
  mNT: 'Maternal & Neonatal Tetanus — Elimination as a Public Health Problem'
}

export const CHART_TITLES_IG12_API = {
  jev: 'Japanese Encephalitis Virus — Control Target',
  MEASLES: 'Measles — Elimination of Transmission Target',
  RUBELLA: 'Rubella — Elimination of Transmission Target',
  hpv: 'Hepatitis B Virus — Elimination of Epidemic Target',
  tetanus: 'Tetanus — Elimination of Epidemic Target',
  hepb: 'Hepatitis B Virus — Elimination of Epidemic Target',
  POLIO: 'Wildtype Poliovirus — Eradication Target',
  NTETANUS:
    'Maternal & Neonatal Tetanus — Elimination as a Public Health Problem'
}
//some are blank so that target type label doesn't get repeated in groupings
export const TARGET_TYPE_IG12 = {
  jev: 'Control',
  measles: 'Elimination of Transmission',
  rubella: '',
  hpv: '',
  tetanus: '',
  hepb: 'Elimination of Epidemic',
  wPV: 'Eradication',
  mNT: 'Elimination as a Public Health Problem'
}

export const TARGET_TYPE_IG12_API = {
  jev: 'Control',
  MEASLES: 'Elimination of Transmission',
  RUBELLA: '',
  hpv: '',
  tetanus: '',
  hepb: 'Elimination of Epidemic',
  POLIO: 'Eradication',
  NTETANUS: 'Elimination as a Public Health Problem'
}
export const COLORS_SP63 = {
  3: 'brightBlue',
  //4: 'mediumBlue',
  2: 'lightestGray',
  //2: 'mediumOrange',
  1: 'darkOrange'
}
export const LABELS_SP63 = {
  3: 'Increased share',
  //4: 'Increasing share but declining expenditure level',
  2: 'Stable share',
  //2: 'Declining share but increasing/stable expenditure level',
  1: 'Decreased share'
}
export const LABELS_SP63_API = {
  Increased: 'Increased share',
  Stable: 'Stable share',
  Decreased: 'Decreased share'
}
export const COLORS_SP62 = {
  3: 'brightBlue',
  2: 'lightestGray',
  1: 'darkOrange'
}
export const COLORS_SP62_API = {
  Decreased: 'darkOrange',
  Stable: 'lightestGray',
  Increased: 'brightBlue'
}
export const LABELS_SP62_API = {
  Increased: 'Increasing expenditure level',
  Stable: 'Stable expenditure level',
  Decreased: 'Declining expenditure level'
}
export const LABELS_SP62 = {
  3: 'Increasing expenditure level',
  2: 'Stable expenditure level',
  1: 'Declining expenditure level'
}

export const LABELS_SP21 = {
  yes: 'With vaccination or other legislation that is supportive as a public good',
  no: 'Without vaccination or other legislation that is supportive as a public good',
  nDNRNoResponse: 'No data/Not relevant/No response'
  //noResponse: 'No response',
  //notRelevant: 'Not relevant'
}

export const LABELS_SP21_API = {
  YES: 'With vaccination or other legislation that is supportive as a public good',
  NO: 'Without vaccination or other legislation that is supportive as a public good',
  ND_NR_NORESPONSE: 'No data/Not relevant/No response'
}

export const LABELS_SP22 = {
  yes: 'With behavioural and social drivers of vaccination',
  no: 'Without behavioural and social drivers of vaccination',
  nDNRNoResponse: 'No data/Not relevant/No response'
  //noResponse: 'No response',
  //notRelevant: 'Not relevant'
}
export const LABELS_SP22_API = {
  YES: 'With a behavioural and social drivers of vaccination',
  NO: 'Without a behavioural and social drivers of vaccination',
  ND_NR_NORESPONSE: 'No data/Not relevant/No response'
}
export const LABELS_SP71 = {
  yes: 'With an immunization research agenda',
  no: 'Without an immunization research agenda',
  nDNRNoResponse: 'No data/Not relevant/No response'
  //noResponse: 'No response',
  //notRelevant: 'Not relevant'
}
export const LABELS_SP71_API = {
  YES: 'With an immunization research agenda',
  NO: 'Without an immunization research agenda',
  ND_NR_NORESPONSE: 'No data/Not relevant/No response'
}

export const PRINT_DIMENSIONS = {
  boundedHeight: 388,
  boundedWidth: 960,
  height: 524,
  width: 1000,
  marginBottom: 64,
  marginLeft: 40,
  marginRight: 16,
  marginTop: 16
}

export const VACCINE_LABELS_IG22 = {
  vaccineIntro: 'Total',
  cOVID: 'Covid',
  dTPBooster: 'DTP Booster',
  hepBBD: 'HepB BD',
  hiB: 'HiB',
  hPV: 'HPV',
  iPV2: 'IPV2',
  pCV: 'PCV',
  rotavirus: 'Rotavirus',
  rubella: 'Rubella',
  cholera: 'Cholera',
  hepA: 'Hep A',
  influenza: 'Influenza',
  jE: 'JE',
  menA: 'MenA',
  multivariantMen: 'Multivariant Men',
  mumps: 'Mumps',
  rabies: 'Rabies',
  typhoid: 'Typhoid',
  varicella: 'Varicella',
  yF: 'Yellow Fever',
  mCV2: 'MCV2'
}

export const VACCINE_LABELS_IG22_API = {
  Total: 'Total',
  COVID19: 'Covid',
  DTP4: 'DTP Booster',
  HepB_BD: 'HepB BD',
  HIB: 'HiB',
  HPV: 'HPV',
  IPV2: 'IPV2',
  PNEUMO_CONJ: 'PCV',
  ROTAVIRUS: 'Rotavirus',
  RUBELLA: 'Rubella',
  //cholera: 'Cholera',
  HEPA: 'Hep A',
  INFLUENZA: 'Influenza',
  //jE: 'JE',
  MEN_A: 'MenA',
  // multivariantMen: 'Multivariant Men',
  MUMPS: 'Mumps',
  // rabies: 'Rabies',
  TYPHOID: 'Typhoid',
  //varicella: 'Varicella',
  YF: 'Yellow Fever',
  MCV2: 'MCV2',
  DTP_BOOSTER_1: 'DTP Booster 1',
  DTP_BOOSTER_2: 'DTP Booster 2',
  DTP_BOOSTER_3: 'DTP Booster 3',
  MALARIA: 'Malaria',
  VARICELLA: 'Varicella'
}

export const MAP_COLOR_PALETTE = {
  Blue1: '#2166ac',
  Blue2: '#4393c3',
  Blue3: '#92c5de',
  Blue4: '#d1e5f0',
  Orange1: '#f7f7f7',
  Orange2: '#fddbc7',
  Orange3: '#f4a582',
  Orange4: '#d6604d',
  Red: '#b2182b',
  NoData: '#C4C4C4',
  DisputedLines: '#656565',
  DisputedAreas: '#FFFFFF'
}

export const MAP_GRADIENT = [
  MAP_COLOR_PALETTE.Red,
  MAP_COLOR_PALETTE.Orange4,
  MAP_COLOR_PALETTE.Orange3,
  MAP_COLOR_PALETTE.Orange2,
  MAP_COLOR_PALETTE.Orange1,
  MAP_COLOR_PALETTE.Blue4,
  MAP_COLOR_PALETTE.Blue3,
  MAP_COLOR_PALETTE.Blue2,
  MAP_COLOR_PALETTE.Blue1
]

export const MAP_THRESHOLDS = [5, 9, 24, 39, 49, 69, 99, 139, 140]

export const FEATURE_PROP = {
  Id: 'iso_a3'
}

export const COUNTRIES = [
  {
    id: 'ABW',
    name: 'Aruba'
  },
  {
    id: 'AFG',
    name: 'Afghanistan'
  },
  {
    id: 'AGO',
    name: 'Angola'
  },
  {
    id: 'ALB',
    name: 'Albania'
  },
  {
    id: 'AND',
    name: 'Andorra'
  },
  {
    id: 'ARE',
    name: 'United Arab Emirates'
  },
  {
    id: 'ARG',
    name: 'Argentina'
  },
  {
    id: 'ARM',
    name: 'Armenia'
  },
  {
    id: 'ASM',
    name: 'American Samoa'
  },
  {
    id: 'ATG',
    name: 'Antigua and Barbuda'
  },
  {
    id: 'AUS',
    name: 'Australia'
  },
  {
    id: 'AUT',
    name: 'Austria'
  },
  {
    id: 'AZE',
    name: 'Azerbaijan'
  },
  {
    id: 'BDI',
    name: 'Burundi'
  },
  {
    id: 'BEL',
    name: 'Belgium'
  },
  {
    id: 'BEN',
    name: 'Benin'
  },
  {
    id: 'BFA',
    name: 'Burkina Faso'
  },
  {
    id: 'BGD',
    name: 'Bangladesh'
  },
  {
    id: 'BGR',
    name: 'Bulgaria'
  },
  {
    id: 'BHR',
    name: 'Bahrain'
  },
  {
    id: 'BHS',
    name: 'Bahamas'
  },
  {
    id: 'BIH',
    name: 'Bosnia and Herzegovina'
  },
  {
    id: 'BLR',
    name: 'Belarus'
  },
  {
    id: 'BLZ',
    name: 'Belize'
  },
  {
    id: 'BMU',
    name: 'Bermuda'
  },
  {
    id: 'BOL',
    name: 'Bolivia (Plurinational State of)'
  },
  {
    id: 'BRA',
    name: 'Brazil'
  },
  {
    id: 'BRB',
    name: 'Barbados'
  },
  {
    id: 'BRN',
    name: 'Brunei Darussalam'
  },
  {
    id: 'BTN',
    name: 'Bhutan'
  },
  {
    id: 'BWA',
    name: 'Botswana'
  },
  {
    id: 'CAF',
    name: 'Central African Republic'
  },
  {
    id: 'CAN',
    name: 'Canada'
  },
  {
    id: 'CHE',
    name: 'Switzerland'
  },
  {
    id: 'CHI',
    name: 'Channel Islands'
  },
  {
    id: 'CHL',
    name: 'Chile'
  },
  {
    id: 'CHN',
    name: 'China'
  },
  {
    id: 'CIV',
    name: "Côte d'Ivoire"
  },
  {
    id: 'CMR',
    name: 'Cameroon'
  },
  {
    id: 'COD',
    name: 'Congo'
  },
  {
    id: 'COG',
    name: 'Congo'
  },
  {
    id: 'COL',
    name: 'Colombia'
  },
  {
    id: 'COM',
    name: 'Comoros'
  },
  {
    id: 'CPV',
    name: 'Cabo Verde'
  },
  {
    id: 'CRI',
    name: 'Costa Rica'
  },
  {
    id: 'CUB',
    name: 'Cuba'
  },
  {
    id: 'CUW',
    name: 'Curacao'
  },
  {
    id: 'CYM',
    name: 'Cayman Islands'
  },
  {
    id: 'CYP',
    name: 'Cyprus'
  },
  {
    id: 'CZE',
    name: 'Czechia'
  },
  {
    id: 'DEU',
    name: 'Germany'
  },
  {
    id: 'DJI',
    name: 'Djibouti'
  },
  {
    id: 'DMA',
    name: 'Dominica'
  },
  {
    id: 'DNK',
    name: 'Denmark'
  },
  {
    id: 'DOM',
    name: 'Dominican Republic'
  },
  {
    id: 'DZA',
    name: 'Algeria'
  },
  {
    id: 'ECU',
    name: 'Ecuador'
  },
  {
    id: 'EGY',
    name: 'Egypt'
  },
  {
    id: 'ERI',
    name: 'Eritrea'
  },
  {
    id: 'ESP',
    name: 'Spain'
  },
  {
    id: 'EST',
    name: 'Estonia'
  },
  {
    id: 'ETH',
    name: 'Ethiopia'
  },
  {
    id: 'FIN',
    name: 'Finland'
  },
  {
    id: 'FJI',
    name: 'Fiji'
  },
  {
    id: 'FRA',
    name: 'France'
  },
  {
    id: 'FRO',
    name: 'Faroe Islands'
  },
  {
    id: 'FSM',
    name: 'Micronesia (Federated States of)'
  },
  {
    id: 'GAB',
    name: 'Gabon'
  },
  {
    id: 'GBR',
    name: 'United Kingdom'
  },
  {
    id: 'GEO',
    name: 'Georgia'
  },
  {
    id: 'GHA',
    name: 'Ghana'
  },
  {
    id: 'GIB',
    name: 'Gibraltar'
  },
  {
    id: 'GIN',
    name: 'Guinea'
  },
  {
    id: 'GMB',
    name: 'Gambia'
  },
  {
    id: 'GNB',
    name: 'Guinea-Bissau'
  },
  {
    id: 'GNQ',
    name: 'Equatorial Guinea'
  },
  {
    id: 'GRC',
    name: 'Greece'
  },
  {
    id: 'GRD',
    name: 'Grenada'
  },
  {
    id: 'GRL',
    name: 'Greenland'
  },
  {
    id: 'GTM',
    name: 'Guatemala'
  },
  {
    id: 'GUM',
    name: 'Guam'
  },
  {
    id: 'GUY',
    name: 'Guyana'
  },
  {
    id: 'HKG',
    name: 'Hong Kong SAR, China'
  },
  {
    id: 'HND',
    name: 'Honduras'
  },
  {
    id: 'HRV',
    name: 'Croatia'
  },
  {
    id: 'HTI',
    name: 'Haiti'
  },
  {
    id: 'HUN',
    name: 'Hungary'
  },
  {
    id: 'IDN',
    name: 'Indonesia'
  },
  {
    id: 'IMN',
    name: 'Isle of Man'
  },
  {
    id: 'IND',
    name: 'India'
  },
  {
    id: 'IRL',
    name: 'Ireland'
  },
  {
    id: 'IRN',
    name: 'Iran (Islamic Republic of) '
  },
  {
    id: 'IRQ',
    name: 'Iraq'
  },
  {
    id: 'ISL',
    name: 'Iceland'
  },
  {
    id: 'ISR',
    name: 'Israel'
  },
  {
    id: 'ITA',
    name: 'Italy'
  },
  {
    id: 'JAM',
    name: 'Jamaica'
  },
  {
    id: 'JOR',
    name: 'Jordan'
  },
  {
    id: 'JPN',
    name: 'Japan'
  },
  {
    id: 'KAZ',
    name: 'Kazakhstan'
  },
  {
    id: 'KEN',
    name: 'Kenya'
  },
  {
    id: 'KGZ',
    name: 'Kyrgyzstan'
  },
  {
    id: 'KHM',
    name: 'Cambodia'
  },
  {
    id: 'KIR',
    name: 'Kiribati'
  },
  {
    id: 'KNA',
    name: 'Saint Kitts and Nevis'
  },
  {
    id: 'KOR',
    name: 'Republic of Korea'
  },
  {
    id: 'KWT',
    name: 'Kuwait'
  },
  {
    id: 'LAO',
    name: "Lao People's Democratic Republic"
  },
  {
    id: 'LBN',
    name: 'Lebanon'
  },
  {
    id: 'LBR',
    name: 'Liberia'
  },
  {
    id: 'LBY',
    name: 'Libya'
  },
  {
    id: 'LCA',
    name: 'Saint Lucia'
  },
  {
    id: 'LIE',
    name: 'Liechtenstein'
  },
  {
    id: 'LKA',
    name: 'Sri Lanka'
  },
  {
    id: 'LSO',
    name: 'Lesotho'
  },
  {
    id: 'LTU',
    name: 'Lithuania'
  },
  {
    id: 'LUX',
    name: 'Luxembourg'
  },
  {
    id: 'LVA',
    name: 'Latvia'
  },
  {
    id: 'MAC',
    name: 'Macao SAR, China'
  },
  {
    id: 'MAF',
    name: 'St. Martin (French part)'
  },
  {
    id: 'MAR',
    name: 'Morocco'
  },
  {
    id: 'MCO',
    name: 'Monaco'
  },
  {
    id: 'MDA',
    name: 'Republic of Moldova'
  },
  {
    id: 'MDG',
    name: 'Madagascar'
  },
  {
    id: 'MDV',
    name: 'Maldives'
  },
  {
    id: 'MEX',
    name: 'Mexico'
  },
  {
    id: 'MHL',
    name: 'Marshall Islands'
  },
  {
    id: 'MKD',
    name: 'North Macedonia'
  },
  {
    id: 'MLI',
    name: 'Mali'
  },
  {
    id: 'MLT',
    name: 'Malta'
  },
  {
    id: 'MMR',
    name: 'Myanmar'
  },
  {
    id: 'MNE',
    name: 'Montenegro'
  },
  {
    id: 'MNG',
    name: 'Mongolia'
  },
  {
    id: 'MNP',
    name: 'Northern Mariana Islands'
  },
  {
    id: 'MOZ',
    name: 'Mozambique'
  },
  {
    id: 'MRT',
    name: 'Mauritania'
  },
  {
    id: 'MUS',
    name: 'Mauritius'
  },
  {
    id: 'MWI',
    name: 'Malawi'
  },
  {
    id: 'MYS',
    name: 'Malaysia'
  },
  {
    id: 'NAM',
    name: 'Namibia'
  },
  {
    id: 'NCL',
    name: 'New Caledonia'
  },
  {
    id: 'NER',
    name: 'Niger'
  },
  {
    id: 'NGA',
    name: 'Nigeria'
  },
  {
    id: 'NIC',
    name: 'Nicaragua'
  },
  {
    id: 'NLD',
    name: 'Netherlands'
  },
  {
    id: 'NOR',
    name: 'Norway'
  },
  {
    id: 'NPL',
    name: 'Nepal'
  },
  {
    id: 'NRU',
    name: 'Nauru'
  },
  {
    id: 'NZL',
    name: 'New Zealand'
  },
  {
    id: 'OMN',
    name: 'Oman'
  },
  {
    id: 'PAK',
    name: 'Pakistan'
  },
  {
    id: 'PAN',
    name: 'Panama'
  },
  {
    id: 'PER',
    name: 'Peru'
  },
  {
    id: 'PHL',
    name: 'Philippines'
  },
  {
    id: 'PLW',
    name: 'Palau'
  },
  {
    id: 'PNG',
    name: 'Papua New Guinea'
  },
  {
    id: 'POL',
    name: 'Poland'
  },
  {
    id: 'PRI',
    name: 'Puerto Rico'
  },
  {
    id: 'PRK',
    name: "Democratic People's Republic of Korea"
  },
  {
    id: 'PRT',
    name: 'Portugal'
  },
  {
    id: 'PRY',
    name: 'Paraguay'
  },
  {
    id: 'PSE',
    name: 'West Bank and Gaza'
  },
  {
    id: 'PYF',
    name: 'French Polynesia'
  },
  {
    id: 'QAT',
    name: 'Qatar'
  },
  {
    id: 'ROU',
    name: 'Romania'
  },
  {
    id: 'RUS',
    name: 'Russian Federation'
  },
  {
    id: 'RWA',
    name: 'Rwanda'
  },
  {
    id: 'SAU',
    name: 'Saudi Arabia'
  },
  {
    id: 'SDN',
    name: 'Sudan'
  },
  {
    id: 'SEN',
    name: 'Senegal'
  },
  {
    id: 'SGP',
    name: 'Singapore'
  },
  {
    id: 'SLB',
    name: 'Solomon Islands'
  },
  {
    id: 'SLE',
    name: 'Sierra Leone'
  },
  {
    id: 'SLV',
    name: 'El Salvador'
  },
  {
    id: 'SMR',
    name: 'San Marino'
  },
  {
    id: 'SOM',
    name: 'Somalia'
  },
  {
    id: 'SRB',
    name: 'Serbia'
  },
  {
    id: 'SSD',
    name: 'South Sudan'
  },
  {
    id: 'STP',
    name: 'Sao Tome and Principe'
  },
  {
    id: 'SUR',
    name: 'Suriname'
  },
  {
    id: 'SVK',
    name: 'Slovakia'
  },
  {
    id: 'SVN',
    name: 'Slovenia'
  },
  {
    id: 'SWE',
    name: 'Sweden'
  },
  {
    id: 'SWZ',
    name: 'Eswatini'
  },
  {
    id: 'SXM',
    name: 'Sint Maarten (Dutch part)'
  },
  {
    id: 'SYC',
    name: 'Seychelles'
  },
  {
    id: 'SYR',
    name: 'Syrian Arab Republic'
  },
  {
    id: 'TCA',
    name: 'Turks and Caicos Islands'
  },
  {
    id: 'TCD',
    name: 'Chad'
  },
  {
    id: 'TGO',
    name: 'Togo'
  },
  {
    id: 'THA',
    name: 'Thailand'
  },
  {
    id: 'TJK',
    name: 'Tajikistan'
  },
  {
    id: 'TKM',
    name: 'Turkmenistan'
  },
  {
    id: 'TLS',
    name: 'Timor-Leste'
  },
  {
    id: 'TON',
    name: 'Tonga'
  },
  {
    id: 'TTO',
    name: 'Trinidad and Tobago'
  },
  {
    id: 'TUN',
    name: 'Tunisia'
  },
  {
    id: 'TUR',
    name: 'Turkey'
  },
  {
    id: 'TUV',
    name: 'Tuvalu'
  },
  {
    id: 'TZA',
    name: 'United Republic of Tanzania'
  },
  {
    id: 'UGA',
    name: 'Uganda'
  },
  {
    id: 'UKR',
    name: 'Ukraine'
  },
  {
    id: 'URY',
    name: 'Uruguay'
  },
  {
    id: 'USA',
    name: 'United States of America'
  },
  {
    id: 'UZB',
    name: 'Uzbekistan'
  },
  {
    id: 'VCT',
    name: 'Saint Vincent and the Grenadines'
  },
  {
    id: 'VEN',
    name: 'Venezuela (Bolivarian Republic of)'
  },
  {
    id: 'VGB',
    name: 'British Virgin Islands'
  },
  {
    id: 'VIR',
    name: 'Virgin Islands (U.S.)'
  },
  {
    id: 'VNM',
    name: 'Viet Nam'
  },
  {
    id: 'VUT',
    name: 'Vanuatu'
  },
  {
    id: 'WSM',
    name: 'Samoa'
  },
  {
    id: 'XKX',
    name: 'Kosovo'
  },
  {
    id: 'YEM',
    name: 'Yemen'
  },
  {
    id: 'ZAF',
    name: 'South Africa'
  },
  {
    id: 'ZMB',
    name: 'Zambia'
  },
  {
    id: 'ZWE',
    name: 'Zimbabwe'
  }
]
export const LINE_COLORS_SP32 = {
  dTPCV3: 'brightBlue',
  mCV1: 'mint',
  mCV2: 'purple'
}

//this translates codes already used in app to new codes used in wisemart
export const REGION_LOOKUP = {
  GBL: 'GLOBAL',
  AFR: 'AFRO',
  AMR: 'AMRO',
  EMR: 'EMRO',
  EUR: 'EURO',
  SEAR: 'SEARO',
  WPR: 'WPRO',
  EAPR: 'EAPRO',
  ECAR: 'ECARO',
  ESAR: 'ESARO',
  LACR: 'LACRO',
  MENA: 'MENA',
  ROSA: 'ROSA',
  WCAR: 'WCARO',
  WBLIC: 'Low income',
  WBLMIC: 'Lower middle income',
  WBUMIC: 'Upper middle income',
  WBHIC: 'High income'
}
export const REGION_COUNTRIES = {
  EMR: [
    'Afghanistan',
    'Bahrain',
    'Djibouti',
    'Egypt',
    'Iran (Islamic Republic of)',
    'Iraq',
    'Jordan',
    'Kuwait',
    'Lebanon',
    'Libya',
    'Morocco',
    'Oman',
    'Pakistan',
    'Qatar',
    'Saudi Arabia',
    'Somalia',
    'Sudan',
    'Syrian Arab Republic',
    'Tunisia',
    'United Arab Emirates',
    'Yemen'
  ],
  AFR: [
    'Algeria',
    'Angola',
    'Benin',
    'Botswana',
    'Burkina Faso',
    'Burundi',
    'Cameroon',
    'Cabo Verde',
    'Central African Republic',
    'Chad',
    'Comoros',
    'Congo',
    "Côte d'Ivoire",
    'Democratic Republic of the Congo',
    'Equatorial Guinea',
    'Eritrea',
    'Eswatini',
    'Ethiopia',
    'Gabon',
    'Gambia',
    'Ghana',
    'Guinea',
    'Guinea-Bissau',
    'Kenya',
    'Lesotho',
    'Liberia',
    'Madagascar',
    'Malawi',
    'Mali',
    'Mauritania',
    'Mauritius',
    'Mozambique',
    'Namibia',
    'Niger',
    'Nigeria',
    'Rwanda',
    'Sao Tome and Principe',
    'Senegal',
    'Seychelles',
    'Sierra Leone',
    'South Africa',
    'South Sudan',
    'Togo',
    'Uganda',
    'United Republic of Tanzania',
    'Zambia',
    'Zimbabwe'
  ],
  AMR: [
    'Antigua and Barbuda',
    'Argentina',
    'Bahamas',
    'Barbados',
    'Belize',
    'Bolivia (Plurinational State of)',
    'Brazil',
    'Canada',
    'Chile',
    'Colombia',
    'Costa Rica',
    'Cuba',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'El Salvador',
    'Grenada',
    'Guatemala',
    'Guyana',
    'Haiti',
    'Honduras',
    'Jamaica',
    'Mexico',
    'Nicaragua',
    'Panama',
    'Paraguay',
    'Peru',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Suriname',
    'Trinidad and Tobago',
    'United States of America',
    'Uruguay',
    'Venezuela (Bolivarian Republic of)'
  ],
  EUR: [
    'Albania',
    'Andorra',
    'Armenia',
    'Austria',
    'Azerbaijan',
    'Belarus',
    'Belgium',
    'Bosnia and Herzegovina',
    'Bulgaria',
    'Croatia',
    'Cyprus',
    'Czechia',
    'Denmark',
    'Estonia',
    'Finland',
    'France',
    'Georgia',
    'Germany',
    'Greece',
    'Hungary',
    'Iceland',
    'Ireland',
    'Israel',
    'Italy',
    'Kazakhstan',
    'Kyrgyzstan',
    'Latvia',
    'Lithuania',
    'Luxembourg',
    'Malta',
    'Monaco',
    'Montenegro',
    'Netherlands',
    'North Macedonia',
    'Norway',
    'Poland',
    'Portugal',
    'Republic of Moldova',
    'Romania',
    'Russian Federation',
    'San Marino',
    'Serbia',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
    'Switzerland',
    'Tajikistan',
    'Turkey',
    'Turkmenistan',
    'Ukraine',
    'United Kingdom',
    'Uzbekistan'
  ],
  SEAR: [
    'Bangladesh',
    'Bhutan',
    "Democratic People's Republic of Korea",
    'India',
    'Indonesia',
    'Maldives',
    'Myanmar',
    'Nepal',
    'Sri Lanka',
    'Thailand',
    'Timor-Leste'
  ],
  WPR: [
    'Australia',
    'Brunei Darussalam',
    'Cambodia',
    'China',
    'Cook Islands',
    'Fiji',
    'Japan',
    'Kiribati',
    "Lao People's Democratic Republic",
    'Malaysia',
    'Marshall Islands',
    'Micronesia (Federated States of)',
    'Mongolia',
    'Nauru',
    'New Zealand',
    'Niue',
    'Palau',
    'Papua New Guinea',
    'Philippines',
    'Republic of Korea',
    'Samoa',
    'Singapore',
    'Solomon Islands',
    'Tonga',
    'Tuvalu',
    'Vanuatu',
    'Viet Nam'
  ],
  EAPR: [
    'Brunei Darussalam',
    'Cambodia',
    'China',
    'Cook Islands',
    "Democratic People's Republic of Korea",
    'Fiji',
    'Indonesia',
    'Kiribati',
    "Lao People's Democratic Republic",
    'Malaysia',
    'Marshall Islands',
    'Micronesia (Federated States of)',
    'Mongolia',
    'Myanmar',
    'Nauru',
    'Niue',
    'Palau',
    'Papua New Guinea',
    'Philippines',
    'Republic of Korea',
    'Samoa',
    'Singapore',
    'Solomon Islands',
    'Thailand',
    'Timor-Leste',
    'Tonga',
    'Tuvalu',
    'Vanuatu',
    'Viet Nam'
  ],
  ECAR: [
    'Albania',
    'Armenia',
    'Azerbaijan',
    'Belarus',
    'Bosnia and Herzegovina',
    'Bulgaria',
    'Croatia',
    'Georgia',
    'Kazakhstan',
    'Kyrgyzstan',
    'Montenegro',
    'North Macedonia',
    'Republic of Moldova',
    'Romania',
    'Russian Federation',
    'Serbia',
    'Tajikistan',
    'Turkey',
    'Turkmenistan',
    'Ukraine',
    'Uzbekistan'
  ],
  ESAR: [
    'Angola',
    'Botswana',
    'Burundi',
    'Comoros',
    'Eritrea',
    'Eswatini',
    'Ethiopia',
    'Kenya',
    'Lesotho',
    'Madagascar',
    'Malawi',
    'Mauritius',
    'Mozambique',
    'Namibia',
    'Rwanda',
    'Seychelles',
    'Somalia',
    'South Africa',
    'South Sudan',
    'Uganda',
    'United Republic of Tanzania',
    'Zambia',
    'Zimbabwe'
  ],
  LACR: [
    'Antigua and Barbuda',
    'Argentina',
    'Bahamas',
    'Barbados',
    'Belize',
    'Bolivia (Plurinational State of)',
    'Brazil',
    'Chile',
    'Colombia',
    'Costa Rica',
    'Cuba',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'El Salvador',
    'Grenada',
    'Guatemala',
    'Guyana',
    'Haiti',
    'Honduras',
    'Jamaica',
    'Mexico',
    'Nicaragua',
    'Panama',
    'Paraguay',
    'Peru',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Suriname',
    'Trinidad and Tobago',
    'Uruguay',
    'Venezuela (Bolivarian Republic of)'
  ],
  MENA: [
    'Algeria',
    'Bahrain',
    'Djibouti',
    'Egypt',
    'Iran (Islamic Republic of)',
    'Iraq',
    'Jordan',
    'Kuwait',
    'Lebanon',
    'Libya',
    'Morocco',
    'Oman',
    'Qatar',
    'Saudi Arabia',
    'Sudan',
    'Syrian Arab Republic',
    'Tunisia',
    'United Arab Emirates',
    'Yemen'
  ],
  ROSA: [
    'Afghanistan',
    'Bangladesh',
    'Bhutan',
    'India',
    'Maldives',
    'Nepal',
    'Pakistan',
    'Sri Lanka'
  ],
  WCAR: [
    'Benin',
    'Burkina Faso',
    'Cameroon',
    'Cabo Verde',
    'Central African Republic',
    'Chad',
    'Congo',
    "Côte d'Ivoire",
    'Democratic Republic of the Congo',
    'Equatorial Guinea',
    'Gabon',
    'Gambia',
    'Ghana',
    'Guinea',
    'Guinea-Bissau',
    'Liberia',
    'Mali',
    'Mauritania',
    'Niger',
    'Nigeria',
    'Sao Tome and Principe',
    'Senegal',
    'Sierra Leone',
    'Togo'
  ],
  WBHIC: [
    'Andorra',
    'Antigua and Barbuda',
    'Australia',
    'Austria',
    'Bahamas',
    'Bahrain',
    'Barbados',
    'Belgium',
    'Brunei Darussalam',
    'Canada',
    'Chile',
    'Croatia',
    'Cyprus',
    'Czechia',
    'Denmark',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Greece',
    'Guyana',
    'Hungary',
    'Iceland',
    'Ireland',
    'Israel',
    'Italy',
    'Japan',
    'Kuwait',
    'Latvia',
    'Lithuania',
    'Luxembourg',
    'Malta',
    'Monaco',
    'Nauru',
    'Netherlands (Kingdom of the)',
    'New Zealand',
    'Norway',
    'Oman',
    'Panama',
    'Poland',
    'Portugal',
    'Qatar',
    'Republic of Korea',
    'Romania',
    'Saint Kitts and Nevis',
    'San Marino',
    'Saudi Arabia',
    'Seychelles',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
    'Switzerland',
    'Trinidad and Tobago',
    'United Arab Emirates',
    'United Kingdom of Great Britain and Northern Ireland',
    'United States of America',
    'Uruguay'
  ],
  WBLIC: [
    'Afghanistan',
    'Burkina Faso',
    'Burundi',
    'Central African Republic',
    'Chad',
    "Democratic People's Republic of Korea",
    'Democratic Republic of the Congo',
    'Eritrea',
    'Ethiopia',
    'Gambia',
    'Guinea-Bissau',
    'Liberia',
    'Madagascar',
    'Malawi',
    'Mali',
    'Mozambique',
    'Niger',
    'Rwanda',
    'Sierra Leone',
    'Somalia',
    'South Sudan',
    'Sudan',
    'Syrian Arab Republic',
    'Togo',
    'Uganda',
    'Yemen'
  ],
  WBLMIC: [
    'Algeria',
    'Angola',
    'Bangladesh',
    'Benin',
    'Bhutan',
    'Bolivia (Plurinational State of)',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Comoros',
    'Congo',
    "Côte d'Ivoire",
    'Djibouti',
    'Egypt',
    'Eswatini',
    'Ghana',
    'Guinea',
    'Haiti',
    'Honduras',
    'India',
    'Iran (Islamic Republic of)',
    'Jordan',
    'Kenya',
    'Kiribati',
    'Kyrgyzstan',
    "Lao People's Democratic Republic",
    'Lebanon',
    'Lesotho',
    'Mauritania',
    'Micronesia (Federated States of)',
    'Mongolia',
    'Morocco',
    'Myanmar',
    'Nepal',
    'Nicaragua',
    'Nigeria',
    'Pakistan',
    'Papua New Guinea',
    'Philippines',
    'Samoa',
    'Sao Tome and Principe',
    'Senegal',
    'Solomon Islands',
    'Sri Lanka',
    'Tajikistan',
    'Timor-Leste',
    'Tunisia',
    'Ukraine',
    'United Republic of Tanzania',
    'Uzbekistan',
    'Vanuatu',
    'Viet Nam',
    'Zambia',
    'Zimbabwe'
  ],
  WBUMIC: [
    'Albania',
    'Argentina',
    'Armenia',
    'Azerbaijan',
    'Belarus',
    'Belize',
    'Bosnia and Herzegovina',
    'Botswana',
    'Brazil',
    'Bulgaria',
    'China',
    'Colombia',
    'Costa Rica',
    'Cuba',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'El Salvador',
    'Equatorial Guinea',
    'Fiji',
    'Gabon',
    'Georgia',
    'Grenada',
    'Guatemala',
    'Indonesia',
    'Iraq',
    'Jamaica',
    'Kazakhstan',
    'Libya',
    'Malaysia',
    'Maldives',
    'Marshall Islands',
    'Mauritius',
    'Mexico',
    'Montenegro',
    'Namibia',
    'North Macedonia',
    'Palau',
    'Paraguay',
    'Peru',
    'Republic of Moldova',
    'Russian Federation',
    'Saint Lucia',
    'Saint Vincent and the Grenadines',
    'Serbia',
    'South Africa',
    'Suriname',
    'Thailand',
    'Tonga',
    'Turkey',
    'Turkmenistan',
    'Tuvalu',
    'Venezuela (Bolivarian Republic of)'
  ]
}
